<template>
  <div v-if="customer">
    <loading v-if="displayLoading"></loading>

    <header class="d-flex justify-content-between align-items-center">
      <span class="text-left">
        <b-link
          class="btn text-white text-nowrap"
          :to="{
            name: 'LiffSalesCustomerList_Chailease',
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
            <path
              d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
            />
          </svg>
          回列表
        </b-link>
      </span>
      <h1>客戶資料</h1>
      <span class="text-right"> </span>
    </header>

    <validation-error-alert
      :errors="validationErrors"
      v-if="validationErrors"
    ></validation-error-alert>

    <div class="d-flex align-items-center p-3">
      <b-avatar class="mr-3" :src="`${customer.avatar_url}`"></b-avatar>
      <div class="mr-auto font-weight-bold">
        {{ customer.name }}
        <b-badge
          pill
          variant="light"
          class="text-dark"
          v-for="t in customer.tags"
          :key="t"
        >
          {{ t }}
        </b-badge>
      </div>
      <b-button
        v-if="themeConfig.show_client_chat_button"
        size="xs"
        variant="primary"
        class="m text-nowrap"
        href="https://chat.line.biz/"
        target="_blank"
      >
        <BIconChatText class="button-icon"></BIconChatText>
        開始對談
      </b-button>
      <b-button
        v-if="themeConfig.show_client_patch_button"
        size="xs"
        variant="primary"
        @click="clientPatchClick"
        class="m text-nowrap"
      >
        <BIconFiles class="button-icon"></BIconFiles>
        通知送補件
      </b-button>
    </div>

    <ul class="customer-tab nav nav-tabs d-flex justify-content-between">
      <li class="nav-item flex-fill">
        <a
          :class="`nav-link ${currentTab == 'profile' && 'active'}`"
          @click="
            () => {
              currentTab = 'profile';
            }
          "
          >個人資料</a
        >
      </li>
      <li class="nav-item flex-fill">
        <a
          v-if="themeConfig.show_job_description"
          :class="`nav-link ${currentTab == 'description' && 'active'}`"
          @click="
            () => {
              currentTab = 'description';
            }
          "
          >工作描述</a
        >
      </li>
    </ul>

    <div v-if="currentTab == 'profile'" class="my-2">
      <table class="table table-borderless">
        <tr>
          <th class="table-th-title">聯絡電話</th>
          <td>{{ customer.mobile_phone }}</td>
        </tr>
        <template
          v-if="profile_mapping && themeConfig.customer_info_fields_all"
        >
          <tr v-for="i in customerProfiles" :key="i.key">
            <th class="table-th-title">
              {{ getDisplayName(i.key) }}
            </th>
            <td>{{ i.value }}</td>
          </tr>
        </template>

        <tr v-if="false">
          <td colspan="2">
            <b-button
              size="lg"
              variant="light"
              class="text-primary w-100 font-weight-bold"
              @click="
                () => {
                  collapsed = !collapsed;
                }
              "
            >
              {{ collapsed ? "顯示更多" : "顯示更少" }}

              <svg
                v-if="collapsed"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                style="fill: #1e88e5"
              >
                <path
                  d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                />
              </svg>

              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                style="fill: #1e88e5"
              >
                <path
                  d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                />
              </svg>
            </b-button>
          </td>
        </tr>
        <template v-if="!collapsed">
          <template v-for="i in profile_mapping">
            <tr v-if="customer.profiles[i.key]" :key="i.key">
              <th>{{ i.display_name }}</th>
              <td>{{ customer.profiles[i.key] }}</td>
            </tr>
          </template>
        </template>
        <tr>
          <th class="memo-label">客戶備註</th>
          <td>
            <div
              class="memo-display"
              v-if="!edit"
              @click="
                () => {
                  edit = !edit;
                }
              "
              v-html="remark !== null ? remarkText : '點擊填寫備註'"
            ></div>

            <template v-else>
              <b-form-textarea
                v-model="remark"
                placeholder="點擊填寫備註"
                rows="6"
              ></b-form-textarea>
              <div class="d-flex justify-content-between py-2">
                <b-button
                  size="sm"
                  variant="outline-dark mr-2 w-100"
                  :disabled="buttonLoading"
                  @click="
                    () => {
                      edit = !edit;
                    }
                  "
                  >取消</b-button
                >
                <b-button
                  size="sm"
                  variant="primary w-100"
                  :disabled="buttonLoading"
                  @click="updateCustomerMemo"
                >
                  儲存
                </b-button>
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>

    <div class="description" v-else>
      <table class="table table-borderless">
        <tr>
          <th>方式</th>
          <td>
            <b-row>
              <b-col v-for="i in visitData" :key="i.key" cols="6" class="p-2">
                <label
                  @click="
                    () => {
                      description.visit = i.key;
                    }
                  "
                >
                  <svg
                    v-if="description.visit == i.key"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    style="fill: #1e88e5"
                  >
                    <path
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    style="fill: #ccc"
                  >
                    <path
                      d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                    />
                  </svg>
                  {{ i.display_name }}
                </label>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr>
          <th>活動目的</th>
          <td>
            <b-row>
              <b-col
                v-for="i in visitData.find((x) => {
                  return x.key == description.visit;
                }).options"
                :key="i.key"
                cols="6"
                class="p-2"
              >
                <label
                  @click="
                    () => {
                      description.purpose = i.key;
                    }
                  "
                >
                  <svg
                    v-if="description.purpose == i.key"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    style="fill: #1e88e5"
                  >
                    <path
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    style="fill: #ccc"
                  >
                    <path
                      d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                    />
                  </svg>
                  {{ i.display_name }}
                </label>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="false">
          <th>分店</th>
          <td>
            <b-select>
              <b-select-option>分店名稱</b-select-option>
            </b-select>
          </td>
        </tr>
        <tr>
          <th>晤談內容</th>
          <td>
            <b-form-textarea
              v-model="description.memo"
              placeholder="寫一些工作描述"
              rows="6"
            ></b-form-textarea>
          </td>
        </tr>
      </table>

      <div class="submit p-3 border-top bg-white">
        <div class="d-flex justify-content-between">
          <b-button
            size="lg"
            variant="primary w-100"
            @click="storeVisitRecord"
            :disabled="buttonLoading"
          >
            <span v-if="!buttonLoading">推播至 EIP</span>
            <b-spinner
              v-else
              small
              class="pl-6"
              variant="secondary"
            ></b-spinner>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import liff from "@line/liff"
import LiffChecker from "@/utils/LiffChecker";
//import _ from "lodash";
import axios from "axios";
import { BIconFiles, BIconChatText } from "bootstrap-vue";

export default {
  components: {
    BIconFiles,
    BIconChatText,
  },
  data() {
    return {
      validationErrors: null,
      buttonLoading: false,
      displayLoading: true,
      currentTab: "profile",
      collapsed: true,
      edit: false,
      description: {
        visit: null,
        purpose: null,
        //branch: null,
        memo: "LINE OA推廣",
      },
      visitData: [],
      remark: "",
      customer: null,
      profile_mapping: null,

      themeConfig: {
        show_client_patch_button: false,
        show_job_description: false,
        customer_info_fields_all: false,
      },
    };
  },
  computed: {
    customerProfiles() {
      return (
        this.customer.profiles &&
        this.customer.profiles.filter((profile) => {
          return this.getDisplayName(profile.key) !== false;
        })
      );
    },
    remarkText() {
      return this.remark.replace(/\n/g, "<br />");
    },
  },
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    async init() {
      let _this = this;

      const orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);

      _this.lineData = await checker.getLineData();

      const headers = {
        Authorization: `Bearer ${_this.lineData.accessToken}`,
      };

      if (checker.initData.theme_config_sales) {
        _this.themeConfig = checker.initData.theme_config_sales;
      }

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/sales/customers/${this.$route.params.id}`,
          { headers }
        )
        .then((response) => {
          _this.customer = response.data.data;
          _this.remark = response.data.data.memo ?? null;
          _this.profile_mapping = response.data.meta.profile_mapping;
        })
        .catch((error) => {
          console.log(error);
        });

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/sales/visit_data`,
          { headers }
        )
        .then((response) => {
          _this.visitData = response.data.data;
          _this.description.visit = _this.visitData[0].key;
          _this.description.purpose = _this.visitData[0].options[0].key;
        })
        .catch((error) => {
          console.log(error);
        });

      _this.displayLoading = false;
    },
    getDisplayName(key) {
      let r = this.profile_mapping.find((x) => {
        return x.key == key;
      });
      return r ? r.display_name : false;
    },
    async clientPatchClick() {
      let _this = this;
      _this.buttonLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.org_code}/liff/sales/customer-patch-upload`;
      const headers = {
        Authorization: `Bearer ${_this.lineData.accessToken}`,
      };

      await axios
        .post(
          url,
          {
            trigger: "staff",
            line_id: _this.lineData.profile.userId,
            line_access_token: _this.lineData.accessToken,
            customer_id: _this.customer.id
          },
          { headers }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$swal.fire({
              title: "送件通知已送出",
              type: "success",
            });
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          let errorMessage = error.response.data.message ?? "發生錯誤";
          this.$swal.fire({
            type: "error",
            text: errorMessage,
          });
          _this.buttonLoading = false;
        });
    },
    async storeVisitRecord() {
      let _this = this;
      _this.buttonLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.org_code}/liff/sales/customers/${_this.customer.id}/store_visit_record`;
      const headers = {
        Authorization: `Bearer ${_this.lineData.accessToken}`,
      };

      await axios
        .post(
          url,
          {
            visit: _this.description.visit,
            purpose: _this.description.purpose,
            memo: _this.description.memo,
          },
          { headers }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.success = true;

            this.$swal.fire({
              title: "資料已送出",
              type: "success",
            });
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response);

          if (error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          } else {
            let errorMessage = error.response.data.message ?? "發生錯誤";
            this.$swal.fire({
              type: "error",
              text: errorMessage,
            });
          }
          _this.buttonLoading = false;
        });
    },
    async updateCustomerMemo() {
      let _this = this;
      _this.buttonLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/${_this.$route.params.org_code}/liff/sales/customers/${_this.customer.id}/update_memo`;
      const headers = {
        Authorization: `Bearer ${_this.lineData.accessToken}`,
      };

      await axios
        .post(
          url,
          {
            memo: _this.remark,
          },
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.success = true;
            this.$swal.fire({
              title: "已更新",
              type: "success",
            });
            this.edit = false;
            return;
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error.response);

          if (error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          } else {
            alert("錯誤");
          }
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  height: 20px;
}

.table {
  table-layout: auto !important;
}
.m{
  padding: .3rem .5rem !important;
}
.b-avatar {
  width: 60px;
  height: 60px;
}

.description {
  padding-bottom: 80px;
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.button-icon {
  margin-right: 5px;
  margin-bottom: -2px;
  margin-top: -2px;
}

.btn {
  margin-left: 8px;
}

.memo-label {
  vertical-align: text-top;
  padding-top: 15px;
}

.memo-display {
  width: 100%;
  height: 118px;
  padding: 1rem 0.75rem;
  border-radius: 0.8rem !important;
  background-color: #f4f6f9;
  border-color: #f4f6f9;
  transition: all;
  transition-duration: 0.3s;
  font-size: 15px;
  overflow: scroll;
}

@media only screen and (max-width: 550px) {
  .table {
    table-layout: fixed;
  }
  .table-th-title {
    width: 120px;
  }
}
</style>
