var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customer)?_c('div',[(_vm.displayLoading)?_c('loading'):_vm._e(),_c('header',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text-left"},[_c('b-link',{staticClass:"btn text-white text-nowrap",attrs:{"to":{
          name: 'LiffSalesCustomerList_Chailease',
        }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 256 512"}},[_c('path',{attrs:{"d":"M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"}})]),_vm._v(" 回列表 ")])],1),_c('h1',[_vm._v("客戶資料")]),_c('span',{staticClass:"text-right"})]),(_vm.validationErrors)?_c('validation-error-alert',{attrs:{"errors":_vm.validationErrors}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center p-3"},[_c('b-avatar',{staticClass:"mr-3",attrs:{"src":("" + (_vm.customer.avatar_url))}}),_c('div',{staticClass:"mr-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.customer.name)+" "),_vm._l((_vm.customer.tags),function(t){return _c('b-badge',{key:t,staticClass:"text-dark",attrs:{"pill":"","variant":"light"}},[_vm._v(" "+_vm._s(t)+" ")])})],2),(_vm.themeConfig.show_client_chat_button)?_c('b-button',{staticClass:"m text-nowrap",attrs:{"size":"xs","variant":"primary","href":"https://chat.line.biz/","target":"_blank"}},[_c('BIconChatText',{staticClass:"button-icon"}),_vm._v(" 開始對談 ")],1):_vm._e(),(_vm.themeConfig.show_client_patch_button)?_c('b-button',{staticClass:"m text-nowrap",attrs:{"size":"xs","variant":"primary"},on:{"click":_vm.clientPatchClick}},[_c('BIconFiles',{staticClass:"button-icon"}),_vm._v(" 通知送補件 ")],1):_vm._e()],1),_c('ul',{staticClass:"customer-tab nav nav-tabs d-flex justify-content-between"},[_c('li',{staticClass:"nav-item flex-fill"},[_c('a',{class:("nav-link " + (_vm.currentTab == 'profile' && 'active')),on:{"click":function () {
            _vm.currentTab = 'profile';
          }}},[_vm._v("個人資料")])]),_c('li',{staticClass:"nav-item flex-fill"},[(_vm.themeConfig.show_job_description)?_c('a',{class:("nav-link " + (_vm.currentTab == 'description' && 'active')),on:{"click":function () {
            _vm.currentTab = 'description';
          }}},[_vm._v("工作描述")]):_vm._e()])]),(_vm.currentTab == 'profile')?_c('div',{staticClass:"my-2"},[_c('table',{staticClass:"table table-borderless"},[_c('tr',[_c('th',{staticClass:"table-th-title"},[_vm._v("聯絡電話")]),_c('td',[_vm._v(_vm._s(_vm.customer.mobile_phone))])]),(_vm.profile_mapping && _vm.themeConfig.customer_info_fields_all)?_vm._l((_vm.customerProfiles),function(i){return _c('tr',{key:i.key},[_c('th',{staticClass:"table-th-title"},[_vm._v(" "+_vm._s(_vm.getDisplayName(i.key))+" ")]),_c('td',[_vm._v(_vm._s(i.value))])])}):_vm._e(),(false)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b-button',{staticClass:"text-primary w-100 font-weight-bold",attrs:{"size":"lg","variant":"light"},on:{"click":function () {
                _vm.collapsed = !_vm.collapsed;
              }}},[_vm._v(" "+_vm._s(_vm.collapsed ? "顯示更多" : "顯示更少")+" "),(_vm.collapsed)?_c('svg',{staticStyle:{"fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512"}},[_c('path',{attrs:{"d":"M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"}})]):_c('svg',{staticStyle:{"fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512"}},[_c('path',{attrs:{"d":"M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"}})])])],1)]):_vm._e(),(!_vm.collapsed)?[_vm._l((_vm.profile_mapping),function(i){return [(_vm.customer.profiles[i.key])?_c('tr',{key:i.key},[_c('th',[_vm._v(_vm._s(i.display_name))]),_c('td',[_vm._v(_vm._s(_vm.customer.profiles[i.key]))])]):_vm._e()]})]:_vm._e(),_c('tr',[_c('th',{staticClass:"memo-label"},[_vm._v("客戶備註")]),_c('td',[(!_vm.edit)?_c('div',{staticClass:"memo-display",domProps:{"innerHTML":_vm._s(_vm.remark !== null ? _vm.remarkText : '點擊填寫備註')},on:{"click":function () {
                _vm.edit = !_vm.edit;
              }}}):[_c('b-form-textarea',{attrs:{"placeholder":"點擊填寫備註","rows":"6"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}}),_c('div',{staticClass:"d-flex justify-content-between py-2"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-dark mr-2 w-100","disabled":_vm.buttonLoading},on:{"click":function () {
                    _vm.edit = !_vm.edit;
                  }}},[_vm._v("取消")]),_c('b-button',{attrs:{"size":"sm","variant":"primary w-100","disabled":_vm.buttonLoading},on:{"click":_vm.updateCustomerMemo}},[_vm._v(" 儲存 ")])],1)]],2)])],2)]):_c('div',{staticClass:"description"},[_c('table',{staticClass:"table table-borderless"},[_c('tr',[_c('th',[_vm._v("方式")]),_c('td',[_c('b-row',_vm._l((_vm.visitData),function(i){return _c('b-col',{key:i.key,staticClass:"p-2",attrs:{"cols":"6"}},[_c('label',{on:{"click":function () {
                    _vm.description.visit = i.key;
                  }}},[(_vm.description.visit == i.key)?_c('svg',{staticStyle:{"fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"}})]):_c('svg',{staticStyle:{"fill":"#ccc"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"}})]),_vm._v(" "+_vm._s(i.display_name)+" ")])])}),1)],1)]),_c('tr',[_c('th',[_vm._v("活動目的")]),_c('td',[_c('b-row',_vm._l((_vm.visitData.find(function (x) {
                return x.key == _vm.description.visit;
              }).options),function(i){return _c('b-col',{key:i.key,staticClass:"p-2",attrs:{"cols":"6"}},[_c('label',{on:{"click":function () {
                    _vm.description.purpose = i.key;
                  }}},[(_vm.description.purpose == i.key)?_c('svg',{staticStyle:{"fill":"#1e88e5"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"}})]):_c('svg',{staticStyle:{"fill":"#ccc"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"}})]),_vm._v(" "+_vm._s(i.display_name)+" ")])])}),1)],1)]),(false)?_c('tr',[_c('th',[_vm._v("分店")]),_c('td',[_c('b-select',[_c('b-select-option',[_vm._v("分店名稱")])],1)],1)]):_vm._e(),_c('tr',[_c('th',[_vm._v("晤談內容")]),_c('td',[_c('b-form-textarea',{attrs:{"placeholder":"寫一些工作描述","rows":"6"},model:{value:(_vm.description.memo),callback:function ($$v) {_vm.$set(_vm.description, "memo", $$v)},expression:"description.memo"}})],1)])]),_c('div',{staticClass:"submit p-3 border-top bg-white"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"size":"lg","variant":"primary w-100","disabled":_vm.buttonLoading},on:{"click":_vm.storeVisitRecord}},[(!_vm.buttonLoading)?_c('span',[_vm._v("推播至 EIP")]):_c('b-spinner',{staticClass:"pl-6",attrs:{"small":"","variant":"secondary"}})],1)],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }